.projectImage {
  flex-grow: 1;
  width: 100%;
  height: 25vh;
  background-size: cover;
  background-position: top left;
  border-radius: 25px;
  overflow: hidden;
  transition: all 5s;
}
.projectImage:hover {
  background-position: bottom right;
}
