.cityButton {
  padding: 0;
  border: none;
  background: none;
  position: relative;
  cursor: pointer;
}
.cityButton::after {
  content: "";
  background-image: linear-gradient(
    to right,
    rgb(216, 198, 252),
    rgb(197, 235, 252)
  );
  height: 16%;
  position: absolute;
  top: 100%;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  left: 0;
  width: 100%;
  z-index: 0;
  transition: 0.3s;
}
.cityButton:hover::after {
  content: "";
  background-image: linear-gradient(
    to right,
    rgb(216, 198, 252),
    rgb(197, 235, 252)
  );
  height: 140%;
  position: absolute;
  top: -5%;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  left: 0;
  width: 100%;
  z-index: 0;
}

.buttonText {
  position: relative;
  z-index: 99;
}
