.pubImage {
  flex-grow: 1;
  width: 100%;
  height: 20vh;
  background-size: cover;
  background-position: top left;
  transition: all 5s;
}
.pubImage:hover {
  background-position: bottom right;
}
