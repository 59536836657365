.nameHeading {
  font-family: PlayfairDisplay;
  font-weight: 900;
  font-size: 10vw;
}

@media screen and (min-width: 481px) {
  .nameHeading {
    font-size: 8vw;
  }
}

@media screen and (min-width: 769px) {
  .nameHeading {
    font-size: 7vw;
    min-width: 50vh;
  }
}

@media screen and (min-width: 1025px) {
  .nameHeading {
    font-size: 5vw;
    min-width: 50vh;
  }
}
