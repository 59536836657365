@font-face {
  font-family: "Georgia";
  src: local("Georgia"), url(./fonts/Georgia/Georgia.ttf) format("truetype");
}
@font-face {
  font-family: "GeorgiaBold";
  src: local("GeorgiaBold"),
    url(./fonts/Georgia/georgia-bold.ttf) format("truetype");
}
@font-face {
  font-family: "GeorgiaItalic";
  src: local("GeorgiaItalic"),
    url(./fonts/Georgia/georgia-italic.ttf) format("truetype");
}
@font-face {
  font-family: "GeorgiaBoldItalic";
  src: local("GeorgiaBoldItalic"),
    url(./fonts/Georgia/georgia-bold-italic.ttf) format("truetype");
}
@font-face {
  font-family: "PlayfairDisplay";
  src: local("PlayfairDisplay"),
    url(./fonts/PlayfairDisplay/PlayfairDisplay-VariableFont_wght.ttf)
      format("truetype");
}
@font-face {
  font-family: "PlayfairDisplayItalic";
  src: local("PlayfairDisplayItalic"),
    url(./fonts/PlayfairDisplay/PlayfairDisplay-Italic-VariableFont_wght.ttf)
      format("truetype");
}
@font-face {
  font-family: "NotoSerifSCMedium";
  src: local("NotoSerifSCMedium"),
    url(./fonts/NotoSerifSC/NotoSerifSC-Medium.otf)
      format("truetype");
}
@font-face {
  font-family: "NotoSerifSCSemiBold";
  src: local("NotoSerifSCSemiBold"),
    url(./fonts/NotoSerifSC/NotoSerifSC-SemiBold.otf)
      format("truetype");
}
@font-face {
  font-family: "NotoSerifSCBlack";
  src: local("NotoSerifSCBlack"),
    url(./fonts/NotoSerifSC/NotoSerifSC-Black.otf)
      format("truetype");
}
@font-face {
  font-family: "LongCangRegular";
  src: local("LongCangRegular"),
    url(./fonts/Long_Cang/LongCang-Regular.ttf)
      format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}